import React, { useState } from "react";
import "../css/Screen1.css";
import { Modalla } from "../components/Modalla";
import { Ordercall } from "../components/Ordercall";
import { DateScript } from "../components/DateScript.js";
import radio from "../icons/radio.svg";
import arrow from "../icons/arrow.svg";
import parnik1 from "../img/parnik1.png";
import parnik2 from "../img/parnik2.png";
import { AiOutlineClose } from "react-icons/ai";

function Screen1mobile() {
  const [modalActiveSM, setModalActiveSM] = useState(false);
  const [infoSendedFormSM, setInfoSendedFormSM] = useState("");
  const closeModal = () => {
    setModalActiveSM(false);
    setInfoSendedFormSM(!infoSendedFormSM);
  };
  return (
    <>
      <div className="screen1mobile">
        <div className="screen1mobile_content1">
          <div className="screen1mobile_content1_text1">
            <h1 className="screen1mobile_content1_h1">
              Уникальный парник “Добрый”
              <br />
              раздвижной, двухсторонний
            </h1>
            <h3 className="screen1mobile_content1_h3">от производителя</h3>
          </div>
          <div className="screen1mobile_content1_text2">
            <h4 className="screen1mobile_content1_h4">
              <span>Купите парник “Добрый”</span>
              <span>{<DateScript />}</span>
              <span>и получите скидку 43%</span>
            </h4>
          </div>
        </div>
        <div className="screen1mobile_content2">
          <div className="screen1_lis">
            <div className="screen1_list">
              <div className="screen1_bul">
                <img
                  className="screen1_bul_img"
                  src={radio}
                  alt="Бесплатная доставка по всей России напрямую от производителя"
                />
              </div>
              <h3 className="screen1_list_item">
                Бесплатная доставка по всей России напрямую
                <br />
                от производителя
              </h3>
            </div>
            <div className="screen1_list">
              <div className="screen1_bul">
                <img
                  className="screen1_bul_img"
                  src={radio}
                  alt="Полный комплект, включая поликарбонат"
                />
              </div>
              <h3 className="screen1_list_item">
                Полный комплект, включая поликарбонат
              </h3>
            </div>
            <div className="screen1_list">
              <div className="screen1_bul">
                <img
                  className="screen1_bul_img"
                  src={radio}
                  alt="Оплата при получении"
                />
              </div>
              <h3 className="screen1_list_item">Оплата при получении</h3>
            </div>
            <div className="screen1_list">
              <div className="screen1_bul">
                <img
                  className="screen1_bul_img"
                  src={radio}
                  alt="Грядка под парник - в ПОДАРОК!"
                />
              </div>
              <h3 className="screen1_list_item">
                Грядка под парник - в ПОДАРОК!
              </h3>
            </div>
            <div className="screen1_list">
              <div className="screen1_bul">
                <img
                  className="screen1_bul_img"
                  src={radio}
                  alt="Простая установка за 1 час"
                />
              </div>
              <h3 className="screen1_list_item">Простая установка за 1 час</h3>
            </div>
          </div>
        </div>
        <div className="screen1mobile_content3">
          <div className="screen1mobile_img">
            <img
              className="screen1mobile_img_img"
              src={parnik1}
              alt="парник Добрый"
            />
          </div>
          <div className="screen1mobile_img screen1mobile_img2">
            <img
              className="screen1mobile_img_img"
              src={parnik2}
              alt="парник Добрый"
            />
          </div>
        </div>
        <div className="screen1mobile_content4">
          <div className="screen1_button">
            <button
              onClick={() => setModalActiveSM(true)}
              className="main_button"
            >
              <span className="screen1_button_text">Заказать звонок</span>
              <img
                className="screen1_button_arrow"
                src={arrow}
                alt="Заказать звонок"
              />
            </button>
          </div>
        </div>
      </div>
      <Modalla active={modalActiveSM} setActive={setModalActiveSM}>
        <Ordercall closeModal={closeModal} />
      </Modalla>
      <div
        className={infoSendedFormSM ? "sended_form active" : "sended_form"}
        onClick={() => setInfoSendedFormSM(!infoSendedFormSM)}
      >
        <div>Ваше сообщение отправлено!</div>
        <div className="ai_close_x">
          <AiOutlineClose
            onClick={() => setInfoSendedFormSM(infoSendedFormSM)}
          />
        </div>
      </div>
    </>
  );
}

export {Screen1mobile}