import React from "react";
import { Link, NavLink } from "react-router-dom";
import logo from "../img/logo.svg";
import phone from "../icons/phone.svg";

function Footermobile() {
  const today = new Date();
  const year = today.getFullYear();
  return (
    <div className="footermobile">
      <div className="footermobile_block footermobile_block_560">
        <NavLink to="/" className="footermobile_logo">
          <div className="footermobile_logo_img">
            <img
              className="footermobile_logo_img_img"
              src={logo}
              alt="Парник Добрый"
            />
          </div>
          <h2 className="footermobile_logo_text">
            <span className="footermobile_logo_text_green">Парник</span>
            <span className="footermobile_logo_text_blue">Добрый</span>
          </h2>
        </NavLink>
        <div className="footermobile_menu">
          <nav className="footermobile_menu_ul">
            <NavLink to="promotions" className="footermobile_menu_link">
              Акции
            </NavLink>
            <NavLink to="reviews" className="footermobile_menu_link">
              Отзывы
            </NavLink>
            <NavLink to="contacts" className="footermobile_menu_link">
              Контакты
            </NavLink>
            <NavLink to="about" className="footermobile_menu_link">
              О компании
            </NavLink>
            <NavLink to="gallery" className="footermobile_menu_link">
              Фотогалерея
            </NavLink>
          </nav>
        </div>
        <div className="footermobile_tel">
          <div className="footermobile_call">Звонок по России бесплатный</div>
          <a href="tel:+79373330779" className="footermobile_button">
            <img
              className="footermobile_button_img"
              src={phone}
              alt="Звонок по России бесплатный"
            />
            <span className="footermobile_button_s">+7 937 333-07-79</span>
          </a>
          <NavLink to="polisy">
            <div className="footermobile_polisy">
              Политика конфиденциальности
            </div>
          </NavLink>
          <div className="cr_block">
            <div className="cr cr_text">Сайт создан</div>
            <Link className="cr cr_a" to="https://luckylo.pw/" target="_blank">
              luckylo.pw
            </Link>
          </div>
        </div>
      </div>
      <div className="footermobile_block footermobile_block2">
        <div className="footermobile_contacts">
          <span className="footermobile_contacts1">г. Уфа</span>
          <span className="footermobile_copy">
            Парник Добрый &copy;2018 - {year}
          </span>
        </div>
        <div className="footermobile_contacts footermobile_contacts_r">
          <span className="footermobile_contact">
            ул. Cельская богородская, 58
          </span>
          <span className="footermobile_contact">parnikdobriy@gmail.com</span>
        </div>
        <div className="cr_block_mobile">
          <div className="cr cr_text">Сайт создан</div>
          <Link className="cr cr_a" to="https://luckylo.pw/" target="_blank">
            luckylo.pw
          </Link>
        </div>
      </div>
    </div>
  );
}

export { Footermobile };
