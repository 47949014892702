import React, { useState } from "react";
import { Modalla } from "../components/Modalla";
import { Formreview } from "../components/Formreview";
import "../css/Reviews.css";
import { reviews } from "../Store/Storereviews";
import arrow2 from "../icons/arrow2.svg";
import { AiOutlineClose } from "react-icons/ai";

function Reviews() {
  const [modalActiveReview2, setModalActiveReview2] = useState(false);
  const [infoSendedReview2, setInfoSendedReview2] = useState("");
  const closeModalr2 = () => {
    setModalActiveReview2(false);
    setInfoSendedReview2(!infoSendedReview2);
  };
  const listReviewsinpage = reviews.map((item) => (
    <div className="reviews_card_forpage" key={item.key}>
      <div className="reviews_card_block1">
        <div className="reviews_img">
          <img
            className="reviews_img_img"
            src={require(`../img/reviews/${item.url}`)}
            alt="фото отзвы парник Добрый"
          />
        </div>
        <div className="reviews_card_name_city">
          <div className="reviews_name_white">{item.name}</div>
          <div className={item.className2}>{item.city}</div>
        </div>
      </div>
      <div className="reviews_card_block2">
        <span className={item.classNameColortext}>{item.text}</span>
      </div>
    </div>
  ));
  return (
    <>
      <div className="reviews_page">
        <h1 className="reviews_h1">Отзывы</h1>
        <div className="listReviewsinpage">{listReviewsinpage}</div>
        <div className="send_reviews">
          <button
            onClick={() => setModalActiveReview2(true)}
            className="reviewscomponent_button_b reviewscomponent_button_b_r"
          >
            <img
              className="reviewscomponent_button_arrow reviewscomponent_button_arrow_r"
              src={arrow2}
              alt="оставить отзыв"
            />
            <span className="reviewscomponent_button_text reviewscomponent_button_text_r">
              Оставить отзыв
            </span>
          </button>
        </div>
      </div>
      <Modalla active={modalActiveReview2} setActive={setModalActiveReview2}>
        <Formreview closeModalr={closeModalr2} />
      </Modalla>
      <div
        className={
          infoSendedReview2 ? "sended_form_review active" : "sended_form_review"
        }
        onClick={() => setInfoSendedReview2(!infoSendedReview2)}
      >
        <div>Ваше сообщение отправлено!</div>
        <div className="ai_close_x">
          <AiOutlineClose
            onClick={() => setInfoSendedReview2(infoSendedReview2)}
          />
        </div>
      </div>
    </>
  );
}

export {Reviews}