import React, {useState} from "react";
import "../css/Makeorder.css";
import { Modalla } from "../components/Modalla";
import { Order1 } from "../components/Order1";
import arrow from "../icons/arrow.svg";
import rectl_g from "../icons/rectl_g.svg";
import parnik3 from "../img/parnik3.png";
import plus from "../icons/plus.svg";
import present from "../img/present.png";
import gradka from "../img/gradka.png";
import scoop from "../img/scoop.png";
import makeorder from "../img/makeorder.png";
import makeorder428 from "../img/makeorder428.png";
import { AiOutlineClose } from "react-icons/ai";

function Makeorder() {
  const [modalActiveMO, setModalActiveMO] = useState(false);
  const [infoSendedFormMO, setInfoSendedFormMO] = useState("");
  const closeModal = () => {
    setModalActiveMO(false);
    setInfoSendedFormMO(!infoSendedFormMO);
  };
  return (
    <>
      <div className="makeorder">
        <div className="makeorder_section">
          <div className="makeorder_block1">
            <h2 className="makeorder_h2">
              Закажите парник “Добрый”
              <br />
              сейчас и получите грядку
              <br />
              под парник в подарок.
            </h2>
            <h4 className="makeorder_h4">
              сделайте заказ, оплата при получении
            </h4>
            <div className="makeorder_cost">
              <div className="makeorder_oldprice">
                <span className="makeorder_oldprice_1">21690</span>
                <span className="makeorder_oldprice_2">руб</span>
              </div>
              <div className="makeorder_rectl_g">
                <img
                  className="makeorder_rectl_g_img"
                  src={rectl_g}
                  alt="старая цена парника Добрый"
                />
              </div>
              <div className="makeorder_newprice">
                <span className="makeorder_newprice_1">12390</span>
                <span className="makeorder_newprice_2">руб</span>
              </div>
            </div>
            <div className="makeorder_button">
              <button
                onClick={() => setModalActiveMO(true)}
                className="makeorder_button_b"
              >
                <span className="makeorder_button_text">Купить</span>
                <img
                  className="makeorder_button_arrow"
                  src={arrow}
                  alt="купить парник Добрый"
                />
              </button>
            </div>
          </div>
          <div className="makeorder_block2">
            <div className="makeorder_block2_img">
              <img
                className="makeorder_block2_img_img"
                src={parnik3}
                alt="Купить парник Добрый"
              />
            </div>
            <div className="makeorder_block2_plus">
              <img
                className="makeorder_block2_plus_img"
                src={plus}
                alt="парник Добрый"
              />
            </div>
            <div className="makeorder_block2_present">
              <img
                className="makeorder_block2_present_img"
                src={present}
                alt="Купить парник Добрый грядка под парник в подарок"
              />
            </div>
            <div className="makeorder_block2_img2">
              <img
                className="makeorder_block2_img2_img"
                src={gradka}
                alt="грядка под парник в подарок"
              />
            </div>
          </div>
          <div className="makeorder_block_imgs_768">
            <img
              className="makeorder_block_imgs_768_img"
              src={makeorder}
              alt="Купить парник Добрый"
            />
          </div>
          <div className="makeorder_block_imgs_428">
            <img
              className="makeorder_block_imgs_428_img"
              src={makeorder428}
              alt="Купить парник Добрый"
            />
          </div>
        </div>
        <div className="scoop">
          <img className="scoop_img" src={scoop} alt="почвогрунт" />
        </div>
      </div>
      <Modalla active={modalActiveMO} setActive={setModalActiveMO}>
        <Order1 closeModal={closeModal} />
      </Modalla>
      <div
        className={infoSendedFormMO ? "sended_form active" : "sended_form"}
        onClick={() => setInfoSendedFormMO(!infoSendedFormMO)}
      >
        <div>Ваше сообщение отправлено!</div>
        <div className="ai_close_x">
          <AiOutlineClose
            onClick={() => setInfoSendedFormMO(infoSendedFormMO)}
          />
        </div>
      </div>
    </>
  );
}

export { Makeorder };
