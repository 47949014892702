import React, { useRef, useState, useEffect } from "react";
import emailjs from "@emailjs/browser";
import "../css/Ordercall.css";
import "../css/Formreview.css";
import { NavLink } from "react-router-dom";
import people from "../img/people.png";
import { BsFillCheckSquareFill } from "react-icons/bs";

const useValidation = (valuesReview, validations) => {
  const [isEmpty, setEmpty] = useState(true);
  const [inputvalid, setInputValid] = useState(false);
  useEffect(() => {
    for (const validation in validations) {
      switch (validation) {
        case "isEmpty":
          valuesReview ? setEmpty(false) : setEmpty(true);
          break;
        default:
      }
    }
  }, [valuesReview, validations]);

  useEffect(() => {
    if (isEmpty) {
      setInputValid(true);
    } else {
      setInputValid(false);
    }
  }, [isEmpty]);

  return {
    isEmpty,
    inputvalid,
  };
};

const useInput = (validations) => {
  const [valuesReview, setValuesReview] = useState("");
  const valid = useValidation(valuesReview, validations);
  const handleChangeReview = (e) => {
    setValuesReview(e.target.value);
    // console.log(valuesReview);
  };
  return {
    valuesReview,
    handleChangeReview,
    ...valid,
  };
};

function Formreview({ closeModalr }) {
  const form4 = useRef();
  const handleSubmitReview = (e) => {
    e.preventDefault();
    console.log(form4);
    emailjs
      .sendForm(
        "service_rux359w",
        "template_3dtn181",
        form4.current,
        "wiYFrGh8M1XybD6p3"
      )
      .then(
        (result) => {
          console.log(result.text);
          closeModalr();
        },
        (error) => {
          console.log(error.text);
        }
      );
  };

  const syleError = {
    color: "red",
    fontSize: "0.9rem",
    justifyContent: "center",
    alignItems: "center",
    fontFamily: "var(--font2)",
    fontStyle: "normal",
    fontWeight: "500",
  };
  const name = useInput({ isEmpty: true });
  const phone = useInput({ isEmpty: true });
  const city = useInput({ isEmpty: true });
  const textrev = useInput({ isEmpty: true });

  return (
    <div className="ordercall">
      <form
        ref={form4}
        onSubmit={handleSubmitReview}
        className="ordercall_section"
      >
        <div className="ordercall_head">
          <div className="ordercall_img">
            <img
              className="ordercall_img_img"
              src={people}
              alt="Заказать звонок"
            />
          </div>
          <h4 className="ordercall_h4">Оставить отзыв</h4>
        </div>
        <div className="ordercall_block">
          <div className="ordercall_block_label">
            <div className="ordercall_label">
              <span className="ordercall_label_text">Имя</span>
              <span className="ordercall_star">*</span>
            </div>
            {name.isEmpty && <div style={syleError}>Заполните поле</div>}
          </div>
          <input
            className="ordercall_input"
            name="name"
            value={name.valuesReview}
            onChange={(e) => name.handleChangeReview(e)}
            // onChange={handleChangeReview}
            // onBlur={(e) => name.handleChangeReview(e)}
            type="text"
            placeholder="Напишите"
            required
          />
        </div>
        <div className="ordercall_block">
          <div className="ordercall_block_label">
            <div className="ordercall_label">
              <span className="ordercall_label_text">Телефон</span>
              <span className="ordercall_star">*</span>
            </div>
            {phone.isEmpty && <div style={syleError}>Заполните поле</div>}
          </div>
          <input
            className="ordercall_input"
            name="phone"
            value={phone.valuesReview}
            onChange={(e) => phone.handleChangeReview(e)}
            // onBlur={(e) => phone.handleChangeReview(e)}
            type="tel"
            placeholder="Напишите"
            required
          />
        </div>
        <div className="ordercall_block">
          <div className="ordercall_block_label">
            <div className="ordercall_label">
              <span className="ordercall_label_text">Город</span>
              <span className="ordercall_star">*</span>
            </div>
            {city.isEmpty && <div style={syleError}>Заполните поле</div>}
          </div>
          <input
            className="ordercall_input"
            name="city"
            value={city.valuesReview}
            onChange={(e) => city.handleChangeReview(e)}
            // onBlur={(e) => city.handleChangeReview(e)}
            type="text"
            placeholder="Напишите"
            required
          />
        </div>
        <div className="ordercall_block">
          <div className="ordercall_block_label">
            <div className="ordercall_label">
              <span className="ordercall_label_text">Текст отзыва</span>
              <span className="ordercall_star">*</span>
            </div>
            {textrev.isEmpty && <div style={syleError}>Заполните поле</div>}
          </div>
          <textarea
            className="ordercall_input formreview_textarea"
            name="textrev"
            value={textrev.valuesReview}
            onChange={(e) => textrev.handleChangeReview(e)}
            // onBlur={(e) => textrev.handleChangeReview(e)}
            type="text"
            placeholder="Напишите"
            required
          />
        </div>
        <div className="ordercall_block_polisy">
          <span className="ordercall_chek">
            <BsFillCheckSquareFill color="rgb(155 228 241)" size="2rem" />
          </span>
          <span className="ordercall_polisy_text">
            Согласие на обработку персональных данных в соответствии с
            <NavLink
              to="polisy"
              className="ordercall_polisy_text ordercall_polisy_text2"
            >
              политикой конфиденциальности
            </NavLink>
          </span>
        </div>
        <div className="ordercall_block_button">
          <button
            disabled={
              name.isEmpty || phone.isEmpty || city.isEmpty || textrev.isEmpty
            }
            // onClick={() => closeModalr()}
            className="ordercall_button"
          >
            Отправить
          </button>
        </div>
      </form>
    </div>
  );
}

export { Formreview };
