import React from 'react'
import "../css/Photogallery.css";
import { Photogallery } from "../components/Photogallery.jsx";

function Gallery() {
  return (
    <div className="gallery">
      <Photogallery />
    </div>
  );
}

export {Gallery}