import React from 'react'
import "../css/Benefits.css";
import parsley1 from "../img/parsley1.png";
import parsley2 from "../img/parsley2.png";
import broccoli from "../img/broccoli.png";
import pepper from "../img/pepper.png";
import cabbage from "../img/cabbage.png";

function Benefits() {
  return (
    <div className="benefits">
      <h2 className="benefits_title">Преимущества конструкции</h2>
      <div className="benefits_block_cards">
        <div className="parsley parsley1">
          <img className="parsley_img" src={parsley2} alt="parsley" />
        </div>
        <div className="benefits_cards">
          <div className="benefits_card benefits_card1">
            <div className="benefits_card_section">
              <div className="benefits_card_section1">
                <div className="benefits_card_img">
                  <img
                    className="benefits_card_img_img"
                    src={broccoli}
                    alt="Надежность конструкции парника Добрый"
                  />
                </div>
                <h3 className="benefits_card_title">Надежность конструкции</h3>
              </div>
              <div className="benefits_card_text">
                Парник “Добрый” имеет монолитные, цельногнутые дуги из
                нержавеющей стали. Качественный поликарбонат толщиной 4мм со
                сроком службы более 12 лет и отличными теплоизоляционными
                свойствами. Поликарбонат отлично пропускает свет, при этом
                задерживая вредные инфракрасные и ультрафиолетовые лучи. Хорошо
                выдерживает ветровые и снеговые нагрузки.
              </div>
            </div>
          </div>
          <div className="benefits_card benefits_card2">
            <div className="benefits_card_section">
              <div className="benefits_card_section1">
                <div className="benefits_card_img">
                  <img
                    className="benefits_card_img_img"
                    src={cabbage}
                    alt="Удобство эксплуатации парника Добрый"
                  />
                </div>
                <h3 className="benefits_card_title">Удобство эксплуатации</h3>
              </div>
              <div className="benefits_card_text">
                Подходит для небольших садовых участков. Легкая сборка, весь
                крепёж в комплекте, ничего не нужно докупать. Удобный полив и
                ухаживание за растениями с двух сторон. Удобно закрывается,
                раздвигается и фиксируется в любом положении. При необходимости
                парник “Добрый” легко перенести на другое место. В холодных
                регионах в таком парнике можно выращивать овощи до поздней
                осени, а в средней полосе - собирать по несколько урожаев.
              </div>
            </div>
          </div>
          <div className="benefits_card benefits_card3">
            <div className="benefits_card_section">
              <div className="benefits_card_section1">
                <div className="benefits_card_img">
                  <img
                    className="benefits_card_img_img"
                    src={pepper}
                    alt="Комфортные условия для многих растений в парнике Добрый"
                  />
                </div>
                <h3 className="benefits_card_title benefits_card_title2">
                  Комфортные условия для многих растений
                </h3>
              </div>
              <div className="benefits_card_text benefits_card_text2">
                Быстрее и лучше созревает урожай. Хорошая вентиляция - парник
                “Добрый” открывается с двух сторон. Когда парник закрыт, тепло
                надежно сохраняется. При ярком солнце парник защищает от
                ультрафиолета. Подходит для выращивания среднерослых овощных
                культур - перец, помидоры, баклажаны, огурцы, арбузы. Для более
                поздних плодоносящих овощей - это дополнительная защита.
              </div>
            </div>
          </div>
        </div>
        <div className="parsley parsley2">
          <img className="parsley_img" src={parsley1} alt="parsley" />
        </div>
      </div>
    </div>
  );
  
}

export {Benefits}