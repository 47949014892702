const reviews = [
  {
    key: Math.round(10000 + Math.random() * 20000),
    id: 1,
    name: "Анна Викторовна",
    classNameColortext: "reviews_text_blue",
    className: "reviews_card_orange",
    className2: "reviews_card_city_darkblue",
    city: "г. Набережные Челны",
    text: "Заказали парник, пришел точно в срок, как и было указано. Место для него давно выделили. И вот, наконец, поставили. У меня небольшой участок на даче, и сложно что-то планировать. Выбрали этот, потому что он небольшой, и как раз встал между грядками. Люблю сажать невысокие томаты, но большого размера плоды. В этом парнике они очень хорошо себя чувствуют, урожай лучше, чем раньше был. Я очень довольна покупкой.",
    url: "review1.png",
  },
  {
    key: Math.round(10000 + Math.random() * 20000),
    id: 2,
    name: "Лариса Леонидовна",
    classNameColortext: "reviews_text_white",
    className: "reviews_card_green1",
    className2: "reviews_card_city_darkblue",
    city: "г. Кемерово",
    text: "Искала парник, и сосед по даче посоветовал взять парник «Добрый». Сказал, что грядку дарят в подарок. Получилась очень выгодная покупка, и привезли быстро. Собрать он мне помог, так как уже свои собрал. Парник открывается с двух сторон, удобно подходить к помидорам, удобно поливать. Я с него столько урожая собрала, дочка даже соседей угощала.",
    url: "review2.png",
  },
  {
    key: Math.round(10000 + Math.random() * 20000),
    id: 3,
    name: "Савелий Максимович",
    classNameColortext: "reviews_text_white",
    className: "reviews_card_green2",
    className2: "reviews_card_city_blue",
    city: "г. Омск",
    text: "Первый парник Добрый брал полтора года назад, сборка простая и понятная, в инструкции разобрался быстро, в комплекте был весь крепеж. Утром собрал, днем уже высадил рассаду. Механизм открывания шторок надежный, ходит хорошо, открывается и закрывается свободно, без усилий. Зимой парник стоял в сугробе, и с ним ничего не случилось. Нашел место для второго такого же, заказал. Пришел быстро, ребята хорошо работают, и предоплата не нужна.",
    url: "review3.png",
  },
  {
    key: Math.round(10000 + Math.random() * 20000),
    id: 4,
    name: "Алёна Петровна",
    classNameColortext: "reviews_text_blue",
    className: "reviews_card_orange",
    className2: "reviews_card_city_darkblue",
    city: "г. Ульяновск",
    text: "Мы живем в Поволжье, у нас тут холодов особо нет, но без парника вообще не обойтись, без защиты все растения горят на солнце. В прошлом году половина саженцев погорели. Поэтому заказали парник. У нас на участке есть один старый, но туда все не помещаются. Привезли, собрали, рассаду уже высадили. У этой компании сказали надежный поликарбонат. А на следующий год и старый парник заменим.",
    url: "review4.png",
  },
  {
    key: Math.round(10000 + Math.random() * 20000),
    id: 5,
    name: "Сергей Васильевич",
    classNameColortext: "reviews_text_white",
    className: "reviews_card_green1",
    className2: "reviews_card_city_darkblue",
    city: "г. Стерлитамак",
    text: "Установил на грядку, где раньше овощи в открытом грунте сажал, парник порадовал простотой конструкции и в то же время он очень удобен в эксплуатации. Открыл, полил, закрыл. Шторки легко двигаются и фиксируются в любом положении. Когда заказывал парник – грядка шла подарком, двойная выгода, получается. Размер парника небольшой, как мне и нужно было, я много не сажаю, так для удовольствия. А урожай неплохой выходит с такой площади, что вдвойне приятно! Спасибо!",
    url: "review5.png",
  },
  {
    key: Math.round(10000 + Math.random() * 20000),
    id: 6,
    name: "Фируза Абазовна",
    classNameColortext: "reviews_text_white",
    className: "reviews_card_green2",
    className2: "reviews_card_city_blue",
    city: "г. Зеленодольск",
    text: "Хочется сделать удобно, а большая теплица мне не под силу. Мы собрали парник вдвоем с сестрой, получилось очень хорошо, даже не пришлось никого просить собрать, инструкция простая. Выбрала этот парник и не пожалела, компактно посадила огурцы, он легко открывается и закрывается, удобные ручки. Можно настроить проветривание с разных сторон, там получается 4 положения, как можно открыть (наполовину или до верха). Когда непогода – мои саженцы надежно спрятаны.",
    url: "review6.png",
  },
  // {
  //   key: Math.round(10000 + Math.random() * 20000),
  //   id: 7,
  //   name: "Максим Александрович",
  //   // classNameforpage: "reviews_card_forpage_green1",
  //   className: "reviews_card_orange",
  //   className2: "reviews_name_white",
  //   className3: "reviews_text_white",
  //   city: "г. Камышин",
  //   text: "Получил парник с доставкой. Пришел на день раньше, чем обещали при заказе. Оплатил при получении (нынче такое доверие к клиентам редкость, спасибо). Хорошо упакован в коробку. Все есть. Собирал сам по инструкции, собирается хорошо. Хорошо, что в комплекте есть грядка – очень продуманная конструкция. Все части друг к другу подходят, щелей нет. Покупкой доволен.",
  //   url: "review7.png",
  // },
  // {
  //   key: Math.round(10000 + Math.random() * 20000),
  //   id: 8,
  //   name: "Сергей Петрович",
  //   // classNameforpage: "reviews_card_forpage_green2",
  //   className: "reviews_card_green1",
  //   className2: "reviews_name_white",
  //   className3: "reviews_text_white",
  //   city: "г. Железнодорожный",
  //   text: "Понравилась цена парника. Да еще и грядка в комплекте, и поликарбонат. Были сомнения, что за такую цену будет что-нибудь не так. Но оплата при получении, так что решил заказать и все сначала проверить. Когда получал, сначала осмотрел, все проверил – действительно все хорошо. Затем оплатил. Буду заказывать еще. Жене очень понравилось, хотим еще теще сделать подарок.",
  //   url: "review8.png",
  // },
];
export { reviews };