import React from 'react'
import "../css/Completeset.css";
import radio from "../icons/radio.svg";
import parnik3 from "../img/parnik3.jpg";
import parsley2 from "../img/parsley2.png";

function Completeset() {
  return (
    <div className="completeset">
      <div className="completeset_parsley">
        <img className="completeset_parsley_img" src={parsley2} alt="parsley" />
      </div>
      <div className="completeset_img">
        <img
          className="completeset_img_img"
          src={parnik3}
          loading="lazy"
          alt="Комплектация парника Добрый"
        />
      </div>
      <div className="completeset_block">
        <h2 className="completeset_h2">
          Комплектация
          <br />
          парника "Добрый"
        </h2>
        <div className="completeset_texts">
          <div className="completeset_li">
            <div className="completeset_list">
              <div className="completeset_bul">
                <img
                  className="completeset_bul_img"
                  src={radio}
                  alt="Оцинкованный каркас парника Добрый"
                />
              </div>
              <div className="completeset_text">Оцинкованный каркас</div>
            </div>
            <div className="completeset_list">
              <div className="completeset_bul">
                <img
                  className="completeset_bul_img"
                  src={radio}
                  alt="Сотовый поликарбонат парника Добрый"
                />
              </div>
              <div className="completeset_text">Сотовый поликарбонат</div>
            </div>
            <div className="completeset_list">
              <div className="completeset_bul">
                <img
                  className="completeset_bul_img"
                  src={radio}
                  alt="Гибкий направляющий ПВХ-профиль парника Добрый"
                />
              </div>
              <div className="completeset_text">
                Гибкий направляющий ПВХ-профиль
              </div>
            </div>
            <div className="completeset_list">
              <div className="completeset_bul">
                <img
                  className="completeset_bul_img"
                  src={radio}
                  alt="Крепеж парника Добрый"
                />
              </div>
              <div className="completeset_text">Крепеж</div>
            </div>
            <div className="completeset_list">
              <div className="completeset_bul">
                <img
                  className="completeset_bul_img"
                  src={radio}
                  alt="Инструкция по сборке парника Добрый"
                />
              </div>
              <div className="completeset_text">Инструкция по сборке</div>
            </div>
            <div className="completeset_list">
              <div className="completeset_bul">
                <img
                  className="completeset_bul_img"
                  src={radio}
                  alt="Оцинкованная грядка парника Добрый"
                />
              </div>
              <div className="completeset_text">
                Оцинкованная грядка
                <br />
                (в подарок)
              </div>
            </div>
          </div>
          <div className="completeset_size">
            <span className="completeset_size_text1">Размеры парника:</span>
            <span className="completeset_size_text2">
              Длина - 2 м, ширина - 1 м, высота - 1,35 м.
            </span>
            <span className="completeset_size_text2 completeset_size_text_b">
              Размер упаковки (ДхШхВ):
            </span>
            <span className="completeset_size_text2">1.6х 1,2 х 1,17 м.</span>
            <span className="completeset_size_text2">Вес - 24 кг</span>
          </div>
          <div className="completeset_text2">
            Для сборки парника вам понадобится только шуруповерт и немного
            времени, около 1 часа. Весь крепёж в комплекте, а поликарбонат уже
            вырезан по размеру.
          </div>
        </div>
      </div>
    </div>
  );
}

export {Completeset}