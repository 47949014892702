import React from "react";
import "../css/Footer.css";
import { Link, NavLink } from "react-router-dom";
import logo from "../img/logo.svg";
import phone from "../icons/phone.svg";
import smile from "../icons/smile.svg";
import finger5 from "../icons/finger5.svg";
// import finger3 from "../icons/finger3.svg";

function Footer() {
  const today = new Date();
  const year = today.getFullYear();
  return (
    <>
      <div className="pre_footer_block">
        <div className="pre_footer_section">
          <div className="pre_footer">
            Получите консультацию прямо сейчас - просто нажмите на номер
            телефона и позвоните нам
          </div>
          <div className="pre_footer_img">
            <img className="pre_footer_img_img" src={smile} alt="smile" />
          </div>
        </div>
        <div className="pre_footer_img2">
          <img className="pre_footer_img2_img" src={finger5} alt="finger" />
        </div>
      </div>
      <div className="footer">
        <div className="footer_block">
          <div className="footer_section">
            <div className="footer_section_logo">
              <div className="footer_logo_l">
                <NavLink to="/" className="footer_section1_logo_link">
                  <div className="footer_logo">
                    <img
                      className="footer_logo_img"
                      width="175"
                      src={logo}
                      alt="лого парник Добрый"
                    />
                  </div>
                  <h2 className="footer_logo_text">
                    <span className="footer_logo_text_colored">Парник</span>
                    <span>Добрый</span>
                  </h2>
                </NavLink>
              </div>
              <div className="footer_logo_copy">
                Парник Добрый &copy;2018 - {year}
              </div>
            </div>
          </div>
          <div className="footer_section_2">
            <div className="footer_section_menu">
              <div className="footer_menu">
                <nav className="footer_menu_block">
                  <NavLink to="promotions" className="footer_menu_item">
                    Акции
                  </NavLink>
                  <NavLink to="reviews" className="footer_menu_item">
                    Отзывы
                  </NavLink>
                  <NavLink to="contacts" className="footer_menu_item">
                    Контакты
                  </NavLink>
                </nav>
                <nav className="footer_menu_block">
                  <NavLink to="about" className="footer_menu_item">
                    О компании
                  </NavLink>
                  <NavLink to="gallery" className="footer_menu_item">
                    Фотогалерея
                  </NavLink>
                </nav>
              </div>
            </div>
            <div className="footer_section_contacts">
              <span className="footer_contact1">г. Уфа</span>
              <span className="footer_contact2">
                ул. Cельская богородская, 58
              </span>
              <span className="footer_contact2">parnikdobriy@gmail.com</span>
            </div>
          </div>
          <div className="footer_section_tel">
            <div className="footer_section_call">
              Звонок по России бесплатный
            </div>
            <div className="footer_section3_block">
              <a href="tel:+79373330779" className="footer_block_phone">
                <div className="footer_icon_phone">
                  <img
                    className="footer_icon_phone_img"
                    src={phone}
                    alt="телефон"
                  />
                </div>
                <div className="footer_phone_number">+7 937 333-07-79</div>
              </a>
            </div>
            <NavLink to="polisy">
              <div className="footer_polisy">Политика конфиденциальности</div>
            </NavLink>
            <div className="cr_block">
              <Link
                className="cr cr_a"
                to="https://luckylo.pw/"
                target="_blank"
              >
                <div className="cr cr_text">Сайт создан luckylo.pw</div>
              </Link>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export { Footer };
