import React from "react";
import "../css/Upac.css";
import parniksize from "../img/parniksize.png";

function Upac() {
  return (
    <div className="upac">
      <h2 className="upac_title">
        Парник легко установить
        <br />в любом месте вашего участка
      </h2>
      <div className="upac_content">
        <div className="upac_content_texts">
          <div className="upac_content_texts_texts">
            <h3 className="upac_content_texts_h3">Размеры парника:</h3>
            <span className="upac_content_text">
              Длина - 2 м<br />
              Ширина - 1 м<br />
              Высота - 1,35 м
            </span>
            <span className="upac_content_text_bold">
              Размер упаковки (ДхШхВ):
            </span>
            <span className="upac_content_text">
              1.6 х 1,2 х 1,17 м<br />
              Вес - 24 кг
            </span>
            <span className="upac_content_text_mobile">
              Длина - 2 м, ширина - 1 м,
            </span>
            <span className="upac_content_text_mobile">
              высота - 1,35 м, вес - 24 кг
            </span>
            <span className="upac_content_text_mobile upac_content_text_mobile_b">
              Размер упаковки (ДхШхВ):
            </span>
            <span className="upac_content_text_mobile">1.6 х 1,2 х 1,17 м</span>
          </div>
          <span className="upac_content_text2">
            В стоимость входит полный комплект: парник, грядка, каркас, крепёж,
            поликарбонат.
          </span>
        </div>
        <div className="upac_content_img">
            <img
              className="upac_content_img_img"
              src={parniksize}
              alt="размеры парника Добрый"
            />
          </div>
      </div>
    </div>
  );
}

export { Upac };
