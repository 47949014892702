import React from 'react'
import "../css/Contacts.css";
import map from "../img/map.jpg";

function Contacts() {
  return (
    <div className="contacts">
      <h1 className="contacts_h1">Контакты</h1>
      <div className="contacts_content">
        <div className="contacts_text">
          <span className="contacts_text_s1">г. Уфа</span>
          <span className="contacts_text_s2">ул. Cельская богородская, 58</span>
          <span className="contacts_text_s2">parnikdobriy@gmail.com</span>
          <span className="contacts_text_s2">+7 937 333-07-79</span>
        </div>
        <div className="contacts_map">
          <img className="contacts_map_img" src={map} alt="контакты парник добрый" />
        </div>
      </div>
    </div>
  );
}

export {Contacts}