import React from 'react'
import "../css/Grendma.css";
import grandma2 from "../img/grandma2.jpg";

function Grendma() {
  return (
    <div className="grendma">
      <div className="grendma_img">
        <img
          className="grendma_img_img"
          src={grandma2}
          alt="Доступная стоимость парника Добрый"
        />
      </div>
      <div className="grendma_block">
        <h3 className="grendma_h3">
          Покупая парник «Добрый», вы получаете возможность вырастить не один
          богатый урожай. Легкий и удобный в эксплуатации этот парник прослужит
          вам ни один год, обеспечит порядок на участке и сохранность растений.
          Прекрасно показал себя в разные регионах России.
        </h3>
      </div>
    </div>
  );
}

export {Grendma}